<template>
  <div>
    <div class="lineup-container">
      <div class="lineup-container__lineup-view">
        <LineupPlayers />
      </div>
    </div>
  </div>
</template>

<script>
import LineupPlayers from '@/components/Sections/LineUp/LineupPlayers';

export default {
  name: 'LineUpView',
  components: {
    LineupPlayers,
  },
};
</script>

<style lang="scss">
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: 100%;
}
.lineup-container {
  display: flex;
  justify-content: center;
  &__lineup-view {
    width: 812px;
    height: auto;
    table-layout: fixed;
  }
}
</style>
